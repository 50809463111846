import React from "react"
import theme from "../themes/theme"

import { useStaticQuery, graphql, Link } from "gatsby"

import { StyledIcon } from "./StyledIcon"

import {
  FooterWrapperElement,
  Footer2ColumnElement,
  FooterLeftColumnElement,
  FooterRightColumnElement,
  FooterRightContentElement,
  FooterLogoElement,
  FooterLink,
  FooterLinkExternal,
} from "../elements/FooterContentElements"

import {
  SocialFacebook,
  SocialLinkedIn,
  SocialInstagram,
  SocialTwitter,
} from "../components/SocialIcons"

const FooterWrapper = (props) => {
  return <FooterWrapperElement {...props}>{props.children}</FooterWrapperElement>
}

const Footer2Column = (props) => {
  return <Footer2ColumnElement {...props}>{props.children}</Footer2ColumnElement>
}

const FooterLeftColumn = (props) => {
  return <FooterLeftColumnElement {...props}>{props.children}</FooterLeftColumnElement>
}

const FooterRightColumn = (props) => {
  return <FooterRightColumnElement {...props}>{props.children}</FooterRightColumnElement>
}

const FooterRightContent = (props) => {
  return <FooterRightContentElement {...props}>{props.children}</FooterRightContentElement>
}

const FooterLogo = (props) => {
  return <FooterLogoElement {...props}>{props.children}</FooterLogoElement>
}

export const FooterContent = (props) => {
  const logoData = useStaticQuery(graphql`
    query FooterQuery {
      logo: file(absolutePath: { regex: "/Horiz-LiftologyProject-Logo.svg/" }) {
        publicURL
      }
    }
  `)

  return (
    <>
      <hr style={{ height: "5px", backgroundColor: theme.colors.primaryOrangeNormal }} />
      <hr style={{ height: "15px", backgroundColor: "black" }} />
      <FooterWrapper style={{ paddingTop: "30px", paddingBottom: "30px" }}>
        <Footer2Column>
          <FooterLeftColumn style={{ paddingLeft: "20px" }}>
            <Link to="/#_home">
              <FooterLogo
                src={logoData.logo.publicURL}
                alt="Liftology Logo"
                padding="10px"
                height="100%"
                width="100%"
                viewbox="0 0 50 10"
              />
              {/* <img
                src={logoData.logo.publicURL}
                alt="Liftology logo"
                viewbox="0 0 5 10"
                height="100%"
                style={{ padding: "10px" }}
              /> */}
            </Link>
          </FooterLeftColumn>
          <FooterRightColumn style={{ paddingLeft: "20px", paddingRight: "20px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginRight: "20px",
                marginLeft: "auto",
                marginTop: "10px",
                marginBottom: "10px",
                textAlign: "center",
              }}>
              <div style={{ marginRight: "30px" }}>
                <FooterinkWrapper to="/contact">CONTACT US</FooterinkWrapper>
              </div>
              <div>
                {/* <Link to="/privacy">PRIVACY POLICY</Link> */}
                <FooterinkWrapper to="/privacy">PRIVACY POLICY</FooterinkWrapper>
              </div>
            </div>
            <div
              style={{
                marginRight: "20px",
                marginLeft: "auto",
                marginBottom: "10px",
                textAlign: "center",
              }}>
              <span>
                &#169; Copyright 2021 by <strong>Jobson Coaching</strong>. All Rights Reserved
              </span>
            </div>
            <div
              style={{
                marginRight: "20px",
                marginLeft: "auto",
                marginBottom: "10px",
              }}>
              71 Doggett Street Newstead 4006
            </div>
            <FooterRightContent>
              <StyledIcon padding="3px" size="30px">
                <SocialLinkedIn paddingLeft="10px" />
                <SocialFacebook paddingLeft="10px" />
                <SocialInstagram paddingLeft="10px" />
                <SocialTwitter paddingLeft="10px" />
              </StyledIcon>
            </FooterRightContent>
          </FooterRightColumn>
        </Footer2Column>
      </FooterWrapper>
    </>
  )
}

const FooterinkWrapper = (props) => {
  const internal = /^\/(?!\/)/.test(props.to)
  if (internal) {
    return <FooterLink to={props.to}>{props.children}</FooterLink>
  }

  return <FooterLinkExternal href={props.to}>{props.children}</FooterLinkExternal>
}
