import styled from "styled-components"
import React from "react"

export const BlogLayoutElement = styled.div`
  display: grid;
  height: 100vh;
  width: 100%;
  overflow: scroll;

  grid-template-rows: auto auto 1fr auto;
  grid-coloumn-gap: 10px;
  grid-template-areas:
    "header"
    "nav"
    "main"
    "footer";

  ${
    "" /* @media screen and (min-width: 720px) {
    grid-template-areas:
      "header header header"
      "nav nav nav"
      "main main main"
      "footer footer footer";
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: minmax(10px, 1fr) repeat (10, minmax(10px, 1fr)) minmax(10px, 1fr);

    grid-template-areas:
      "header header header header header header header header header header header header"
      "nav nav nav nav nav nav nav nav nav nav nav nav"
      "main main main main main main main main main main main main"
      "footer footer footer footer footer footer footer footer footer footer footer footer";
  } */
  }

  h1 {
    ${"" /* font-weight: 700; */}
    ${"" /* line-height: 3.3rem; */}
    ${"" /* font-size: 2.5rem; */}
    font-family: ${(props) => props.theme.fonts.header1};
    font-weight: ${(props) => props.theme.fonts.weight700};
    line-height: ${(props) => props.theme.spacings.large};
    font-size: ${(props) => props.theme.spacings.large};
    color: ${(props) => props.theme.colors.black};
  }

  h2 {
    ${
      "" /* font-weight: 700;
    line-height: 3rem;
    font-size: 2rem; */
    }
    font-family: ${(props) => props.theme.fonts.header2};
    font-weight: ${(props) => props.theme.fonts.weight400};
    line-height: ${(props) => props.theme.spacings.large};
    font-size: ${(props) => props.theme.spacings.large};
    color: ${(props) => props.theme.colors.black};
  }

  h3 {
    font-family: ${(props) => props.theme.fonts.header3};
    font-weight: ${(props) => props.theme.fonts.weight400};
    line-height: ${(props) => props.theme.spacings.xMedium};
    font-size: ${(props) => props.theme.spacings.xMedium};
    color: ${(props) => props.theme.colors.black};
  }

  p {
    ${"" /* font-size: 0.9rem; */}
    ${"" /* font-weight: 300; */}
    ${"" /* line-height: 25px; */}
    ${"" /* line-height: 1.8rem; */}
    ${"" /* font-size: 1.4rem; */}
    font-family: ${(props) => props.theme.fonts.body};
    font-size: ${(props) => props.theme.spacings.small};
    font-weight: ${(props) => props.theme.fonts.weight300};
    line-height: ${(props) => props.theme.spacings.medium};
    color: ${(props) => props.theme.colors.black};
  }
`

export const TPageLayoutElement3 = ({ children }) => {
  return (
    <div
      name="PageLayoutElement"
      id="PageLayoutElement"
      className="h-screen w-full grid grid-flow-col grid-cols-1 sm:grid-cols-lp3 grid-rows-lpGR3 gap-0 border-2 border-black bg-background max-w-screen-2xl">
      {children}
    </div>
  )
}

export const TPageLayoutElement = ({ children }) => {
  return (
    <div
      name="PageLayoutElement"
      id="PageLayoutElement"
      className="flex flex-col w-full bg-background md:max-w-screen-2xl mx-auto">
      {children}
    </div>
  )
}

export const PageLayoutElement = styled.div`
  display: grid;

  ${
    "" /* height: 100vh;
  width: 100vw; */
  }

  overflow: scroll;
  grid-template-rows: auto auto 1fr auto;
  grid-coloumn-gap: 10px;
  grid-template-areas:
    "header"
    "nav"
    "main"
    "footer";

  @media screen and (min-width: 720px) {
    grid-template-areas:
      "header header header"
      "nav nav nav"
      "main main main"
      "footer footer footer";
  }

  ${
    "" /* @media screen and (min-width: 1024px) {
    grid-template-columns: minmax(10px, 1fr) repeat (10, minmax(10px, 1fr)) minmax(10px, 1fr);

    grid-template-areas:
      "header header header header header header header header header header header header"
      "nav nav nav nav nav nav nav nav nav nav nav nav"
      "leftspace leftspace leftspace main main main main main main rightspace rightspace rightspace"
      "footer footer footer footer footer footer footer footer footer footer footer footer";
  } */
  }

  @media screen and (min-width: 1024px) {
    grid-template-columns: 1fr auto 1fr;

    grid-template-areas:
      "header header header"
      "nav nav nav"
      "leftspace main rightspace"
      "footer footer footer";
  }

  h1 {
    ${"" /* font-weight: 700; */}
    ${"" /* line-height: 3.3rem; */}
    ${"" /* font-size: 2.5rem; */}
    font-family: ${(props) => props.theme.fonts.header1};
    font-weight: ${(props) => props.theme.fonts.weight700};
    line-height: ${(props) => props.theme.spacings.large};
    font-size: ${(props) => props.theme.spacings.large};
    color: ${(props) => props.theme.colors.black};
  }

  h2 {
    ${
      "" /* font-weight: 700;
    line-height: 3rem;
    font-size: 2rem; */
    }
    font-family: ${(props) => props.theme.fonts.header2};
    font-weight: ${(props) => props.theme.fonts.weight400};
    line-height: ${(props) => props.theme.spacings.large};
    font-size: ${(props) => props.theme.spacings.large};
    color: ${(props) => props.theme.colors.black};
  }

  h3 {
    font-family: ${(props) => props.theme.fonts.header3};
    font-weight: ${(props) => props.theme.fonts.weight400};
    line-height: ${(props) => props.theme.spacings.xMedium};
    font-size: ${(props) => props.theme.spacings.xMedium};
    color: ${(props) => props.theme.colors.black};
  }

  p {
    ${"" /* font-size: 0.9rem; */}
    ${"" /* font-weight: 300; */}
    ${"" /* line-height: 25px; */}
    ${"" /* line-height: 1.8rem; */}
    ${"" /* font-size: 1.4rem; */}
    font-family: ${(props) => props.theme.fonts.body};
    font-size: ${(props) => props.theme.spacings.small};
    font-weight: ${(props) => props.theme.fonts.weight300};
    line-height: ${(props) => props.theme.spacings.medium};
    color: ${(props) => props.theme.colors.black};
  }
`
export const LeftSpaceElement = styled.div`
  display: none;
  @media screen and (min-width: 1020px) {
    display: flex;
    grid-area: leftspace;
    ${"" /* border: 1px solid purple; */}
  }
`
export const RightSpaceElement = styled.div`
  display: none;
  @media screen and (min-width: 1020px) {
    display: flex;
    grid-area: rightspace;
    ${"" /* border: 1px solid purple; */}
  }
`

export const HeaderElement = styled.div`
  grid-area: header;
  ${"" /* border: 1px solid tomato; */}
`

export const THeaderElement = ({ children }) => {
  return (
    <div
      name="Header"
      id="Header"
      className="col-start-1 col-span-1 row-start-1 row-span-1 md:col-start-2">
      {children}
    </div>
  )
}

export const TNavElement = ({ children }) => {
  return (
    // <div className="col-span-1 row-span-1 row-start-2 flex justify-between items-center content-center sticky top-0 z-10">
    // <div className="col-span-1 row-span-1 row-start-2 flex justify-between items-center content-center z-10">
    <div
      name="Nav"
      id="Nav"
      className="px-4 w-full bg-background col-start-1 col-span-1 md:col-start-2 row-start-1 row-span-1 flex justify-between items-center content-center sticky top-0 z-50">
      {children}
    </div>
  )
}

export const NavElement = styled.nav`
  grid-area: nav;
  display: flex;

  position: -webkit-sticky;
  position: sticky;
  padding: 0.5rem calc((100vw - 1000px) / 4);
  ${"" /* border: 10px solid tomato; */}
  top: 0;
  z-index: 50;
  background-color: ${(props) => props.theme.colors.background};
  justify-content: space-between;
  align-items: center;
  align-content: center;
`
export const TMainElement = ({ children }) => {
  return (
    <div
      name="Main"
      id="Main"
      className="w-full col-start-1 col-span-1 md:col-start-2 row-start-2 row-span-1 flex justify-start flex-col mx-auto">
      {children}
    </div>
  )
}

export const MainElement = styled.div`
  grid-area: main;
  display: flex;
  width: "60%";
  ${"" /* width: 100vw; */}
  align-items: top;
  justify-content: flex-start;
  flex-direction: column;
  ${"" /* margin-bottom: 30px; */}

  ${
    "" /* background-size: contain;
  background-repeat: no-repeat;
  background-position: center; */
  }
`

export const SidebarElement = styled.div`
  grid-area: sidebar;
  bottom: 0;
`
export const TFooterElement = ({ children }) => {
  return (
    <div
      name="Footer"
      id="Footer"
      className="w-full col-start-1 col-span-1 md:col-start-2 row-start-3 row-span-1 bg-LPblack text-LPwhite">
      {children}
    </div>
  )
}

export const FooterElement = styled.div`
  grid-area: footer;
  bottom: 100px;

  background-color: ${(props) => props.theme.colors.black};
  color: ${(props) => props.theme.colors.white};
`
export const TwoOptionsElements = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-bottom: 20px;
  column-gap: 1rem;

  @media screen and (min-width: 720px) {
    flex-direction: row;
  }
`
