import React, { Fragment } from "react"
import { Disclosure, Menu, Transition } from "@headlessui/react"
import { MenuIcon, XIcon, ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/outline"
import { SearchIcon, ArrowRightIcon, ArrowCircleRightIcon } from "@heroicons/react/solid"

import { Link, useStaticQuery, graphql } from "gatsby"

// TODO

function SiteLogo() {
  const SiteLogoData = useStaticQuery(graphql`
    query SiteLogoQuery {
      logo: file(absolutePath: { regex: "/Horiz-LiftologyProject-Logo.svg/" }) {
        publicURL
      }
    }
  `)

  return (
    <img
      src={SiteLogoData.logo.publicURL}
      className="block h-12 w-auto"
      alt="Liftology Project logo"
    />
  )
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

function SearchBar() {
  return (
    <div className="flex-1 flex items-center justify-center px-2  lg:ml-6 lg:justify-end">
      <div className="max-w-lg w-full ">
        <label htmlFor="search" className="sr-only">
          Search
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
          <input
            id="search"
            name="search"
            className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5  placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder="Search"
            type="search"
          />
        </div>
      </div>
    </div>
  )
}

export function Navbar(props) {
  // const isHomePage = () => {
  //   JSON.stringify(this.loc.pathname)
  //   return JSON.stringify(props.loc.pathname) !== '"/"' ? true : false
  // }

  return (
    // <Disclosure as="header" className="bg-white shadow font-sans">
    <Disclosure as="header" className="font-sans w-full">
      {({ open }) => (
        <>
          <div className="w-full mx-auto flex flex-row justify-between items-center bg-background">
            <div className="relative w-full lg:w-auto h-16 flex justify-between">
              <div name="here" className="relative z-50 px-2 flex lg:px-0 ">
                <div className="flex-shrink-0 flex items-center">
                  <Link to="/#_home">
                    <SiteLogo />
                  </Link>
                </div>
              </div>

              <div className="relative z-50 flex items-center lg:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="rounded-md p-2 inline-flex items-center justify-center text-blue-400 hover:bg-blue-100 hover:text-blue-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                  <span className="sr-only">Open menu</span>
                  {open ? (
                    <XIcon className="block h-8 w-8" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-8 w-8" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>

            <div className="flex flex-row justify-between">
              <nav className="hidden lg:py-2 lg:flex lg:space-x-8" aria-label="Global">
                <Link
                  to="/#_home"
                  className="text-primaryBlueNormal hover:bg-gray-50 hover:text-primaryBlueNormal rounded-md py-2 px-3 inline-flex items-center text-base font-semibold">
                  Home
                </Link>
                {props.navigation.topics.map((item, index) => (
                  <>
                    {item.expandable ? (
                      <Menu
                        as="div"
                        className="flex-shrink-0 inline-flex relative ml-4"
                        key={index}>
                        <Menu.Button className="">
                          <span className="sr-only">Open {item.name} menu</span>
                          <div
                            className="flex flex-row text-base hover:bg-gray-50 text-primaryBlueNormal font-semibold items-center"
                            key={index}>
                            {item.name}
                            <ChevronDownIcon
                              className="h-4 w-4 pl-1 hover:bg-gray-50 text-primaryBlueNormal"
                              aria-hidden="true"
                            />
                          </div>
                        </Menu.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95">
                          <Menu.Items className="origin-center absolute left-0 mt-10 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none">
                            {item.childMenu.map((item, cIndex) => (
                              <Menu.Item key={cIndex}>
                                {({ active }) => (
                                  <Link
                                    key={cIndex}
                                    to={item.href}
                                    className={classNames(
                                      active ? "bg-gray-100" : "",
                                      "block py-2 px-4 text-base text-primaryBlueNormal"
                                    )}>
                                    {item.name}
                                  </Link>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    ) : (
                      <>
                        <Link
                          to={item.href}
                          // className="text-gray-900 hover:bg-gray-50 hover:text-gray-900 rounded-md py-2 px-3 inline-flex items-center text-sm font-medium"
                          className="text-primaryBlueNormal hover:bg-gray-50 hover:text-primaryBlueNormal rounded-md py-2 px-3 inline-flex items-center text-base font-semibold"
                          // activeClassName="border-blue-600 border-b-2 rounded-none"
                          partiallyActive={true}>
                          {item.name}
                        </Link>
                      </>
                    )}
                  </>
                ))}
              </nav>
              {/* <div className="hidden justify-end lg:inline-block lg:py-2">
                <SearchBar />
              </div> */}
            </div>

            <div
              className="hidden lg:relative lg:z-50 lg:flex lg:flex-row lg:items-center lg:shadow-xl 
            lg:rounded-2xl lg:border-2 lg:border-primaryBlueNormal lg:bg-primaryBlueNormal lg:text-white hover:text-primaryBlueNormal hover:bg-white hover:border-2 hover:border-primaryBlueNormal">
              <Link to="/free-assessment" className="text-white hover:text-primaryBlueNormal ">
                <div className="flex flex-row p-3 font-semibold text-base items-center">
                  Free Assessment
                  {/* <ChevronRightIcon className="h-6 w-5" /> */}
                  <ArrowCircleRightIcon className="ml-1 h-6 w-5" />
                </div>
              </Link>
            </div>
          </div>

          {/* Mobile Menu */}
          <Disclosure.Panel className="lg:hidden">
            <div className="pt-2 pb-3 space-y-1 border-r-2 border-b-2 shadow">
              {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800" */}
              <Link
                to="/"
                className="border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium">
                Home
              </Link>
              {props.navigation.topics.map((item) => (
                <>
                  {item.expandable ? (
                    <>
                      <span
                        className={
                          item.current
                            ? "bg-blue-50 border-blue-500 text-blue-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                            : "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                        }>
                        {item.name}
                      </span>
                      {item.childMenu.map((childItem) => (
                        <>
                          <Link
                            to={childItem.href}
                            // target="_blank"
                            // rel="nofollow"
                            className={classNames(
                              childItem.current
                                ? "bg-blue-50 border-blue-500 text-blue-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                                : "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium",
                              "ml-4"
                            )}>
                            {childItem.name}
                          </Link>
                        </>
                      ))}
                    </>
                  ) : (
                    <Link
                      to={item.href}
                      // target="_blank"
                      // rel="nofollow"
                      className={classNames(
                        item.current
                          ? "bg-blue-50 border-blue-500 text-blue-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                          : "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                      )}>
                      {item.name}
                    </Link>
                  )}
                </>
              ))}
              <Link
                to="/free-assessment"
                // target="_blank"
                // rel="nofollow"
                className={classNames(
                  // childItem.current
                  // ? "bg-blue-50 border-blue-500 text-blue-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                  // :
                  "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium"
                )}>
                Free Assessment
              </Link>
              <div className="border-t-2 pt-2">
                <div className="text-base font-bold text-gray-600 my-2 ml-2 ">
                  Connect on social
                </div>
                <div className="flex flex-row">
                  {props.navigation.social.map((item) => (
                    <a
                      href={item.href}
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                      className="pl-2">
                      {item.headerIcon}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
