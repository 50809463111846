import styled from "styled-components"

import { Link } from "gatsby"

export const FooterWrapperElement = styled.div``

export const Footer2ColumnElement = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and (min-width: 1024px) {
    flex-direction: row;
  }
`

// export const FooterLeftColumnElement = styled.div`
//   display: flex;
// `

export const FooterLeftColumnElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 1024px) {
    align-items: flex-start;
    justify-content: center;
  }
`

export const FooterRightColumnElement = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 1024px) {
    justify-content: space-evenly;

    margin-right: "0px";
    margin-left: auto;
  }
`

export const FooterRightContentElement = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: "10px";

  align-items: center;
  margin-right: "0px";
  margin-left: "auto";

  @media screen and (min-width: 1024px) {
    margin-left: auto;
    align-items: none;
  }
`

export const FooterLogoElement = styled.img`
  src: ${(props) => props.src};
  alt: ${(props) => props.alt};
  padding: ${(props) => props.padding};
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  viewbox: ${(props) => props.viewbox};
  ${"" /* preserveaspectratio: "none"; */}
`

// src={logoData.logo.publicURL}
// alt="Liftology logo"
// viewbox="0 0 5 10"
// height="100%"
// style={{ padding: "10px" }}

export const FooterLink = styled(Link)`
  display: flex;
  align-items: center;
  margin-left: 24px;
  color: ${(props) => props.theme.colors.background};
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  font-family: ${(props) => props.theme.fonts.menu};
`

export const FooterLinkExternal = styled.a`
  display: flex;
  align-items: center;
  margin-left: 24px;
  color: ${(props) => props.theme.colors.background};
  outline: none;
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  font-family: ${(props) => props.theme.fonts.menu};
`
