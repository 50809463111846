import React from "react"

import { IconContext } from "react-icons"

export const StyledIcon = (props) => {
  return (
    <IconContext.Provider
      value={{
        style: {
          fontSize: props.size ? props.size : "50px",
          padding: props.padding ? props.padding : "0",
          color: props.color,
        },
      }}>
      {props.children}
    </IconContext.Provider>
  )
}
