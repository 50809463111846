import React from "react"
import siteData from "../site/siteData"

import { SocialIconElement } from "../elements/SocialIconsElements"

import { GrLinkedinOption, GrFacebookOption, GrInstagram, GrTwitter } from "react-icons/gr"

export const SocialIcons = (props) => {
  return <div></div>
}

export const SocialFacebook = (props) => {
  return (
    <SocialIconElement {...props}>
      <a
        href={siteData.social.facebook}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Facebook">
        <GrFacebookOption />
      </a>
    </SocialIconElement>
  )
}

export const SocialLinkedIn = (props) => {
  return (
    <SocialIconElement {...props}>
      <a
        href={siteData.social.linkedin}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="LinkedIn">
        <GrLinkedinOption />
      </a>
    </SocialIconElement>
  )
}

export const SocialInstagram = (props) => {
  return (
    <SocialIconElement {...props}>
      <a
        href={siteData.social.instagram}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Instagram">
        <GrInstagram />
      </a>
    </SocialIconElement>
  )
}

export const SocialTwitter = (props) => {
  return (
    <SocialIconElement {...props}>
      <a
        href={siteData.social.twitter}
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Twitter">
        <GrTwitter />
      </a>
    </SocialIconElement>
  )
}
