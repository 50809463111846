import React from "react"

import { FaYoutube, FaLinkedin, FaTwitter, FaFacebook, FaInstagram } from "react-icons/fa"

import {
  TPageLayoutElement,
  THeaderElement,
  TNavElement,
  TMainElement,
  TFooterElement,
  PageLayoutElement,
  LeftSpaceElement,
  RightSpaceElement,
  NavElement,
  HeaderElement,
  MainElement,
  SidebarElement,
  FooterElement,
  // TwoOptionsElements,
  BlogLayoutElement,
} from "../elements/PageElements"

// import { NewNavBar } from "../components/NavBar"

import { Navbar } from "../components/TNavbar"
import { FooterContent } from "../components/FooterContent"

const socialNavigationClassHeader =
  "w-8 h-8 mr-5 text-blue-600 hover:text-blue-900 lg:w-6 lg:h-6 lg:mr-2"

const socialNavigationClassFooter =
  "w-8 h-8 mr-5 text-blue-50 hover:text-blue-200 lg:w-6 lg:h-6 lg:mr-2"

const navigation = {
  topics: [
    { name: "Why Us", href: "/#_whyus", current: false },
    { name: "Testimonials", href: "/#_testimonials", current: false },
    // { name: "Get Footy Fit", href: "/footy-season", current: false },
    {
      name: "Get Fit For...",
      href: "#",
      current: false,
      expandable: true,
      childMenu: [
        { name: "Footy season", href: "/footy-season", current: false },
        // { name: "Snow season", href: "/snow-season", current: false },
        // { name: "Summer beach body", href: "/summer-body", current: false },
      ],
    },
    {
      name: "Our Coaches",
      href: "#",
      current: false,
      expandable: true,
      childMenu: [
        { name: "Mitch Jobson", href: "/coach-mitch-jobson", current: false },
        // { name: "Alex Robinson", href: "/coach-mitch-jobson", current: false },
      ],
    },
    { name: "Blog", href: "/blog", current: false },
    // { name: "Contact", href: "/contact", current: false },
  ],
  social: [
    {
      name: "LinkedIn",
      href: "https://www.linkedin.com/company/liftology-project/",
      headerIcon: <FaLinkedin className={socialNavigationClassHeader} />,
      footerIcon: <FaLinkedin className={socialNavigationClassFooter} />,
      current: false,
    },
    {
      name: "Instagram",
      href: "https://www.instagram.com/liftologyproject/",
      headerIcon: <FaInstagram className={socialNavigationClassHeader} />,
      footerIcon: <FaInstagram className={socialNavigationClassFooter} />,
      current: false,
    },
    {
      name: "Facebook",
      href: "https://www.facebook.com/LiftologyProject/",
      headerIcon: <FaFacebook className={socialNavigationClassHeader} />,
      footerIcon: <FaFacebook className={socialNavigationClassFooter} />,
      current: false,
    },
    {
      name: "Twitter",
      href: "https://twitter.com/liftologyproj",
      headerIcon: <FaTwitter className={socialNavigationClassHeader} />,
      footerIcon: <FaTwitter className={socialNavigationClassFooter} />,
      current: false,
    },
  ],
}

export const PageLayout = ({ children }) => {
  return (
    // <div className="mx-auto max-w-screen-2xl border-2 border-primaryOrangeNormal">
    <TPageLayoutElement>
      {/* <THeader /> */}
      <TNav>
        <Navbar navigation={navigation} />
      </TNav>
      <TMain>
        {/* <div id="_home"></div> */}
        <div id="_home" className="text-xs">
          &nbsp;
        </div>
        {children}
      </TMain>
      <TFooter>
        <FooterContent />
      </TFooter>
    </TPageLayoutElement>
    // </div>
  )
}

// export const PageLayout = ({ children }) => {
//   return (
//     <PageLayoutElement>
//       <Header>
//         <Nav>
//           {/* <NavBar /> */}
//           {/* <div className="flex flex-col"> */}
//           {/* <NewNavBar /> */}
//           <Navbar navigation={navigation} />
//           {/* </div> */}
//         </Nav>
//       </Header>
//       <LeftSpace>&nbsp;</LeftSpace>
//       <Main>
//         {/* <div id="_home"></div> */}
//         <div id="_home" className="text-xs">
//           &nbsp;
//         </div>
//         {children}
//       </Main>
//       <RightSpace>&nbsp;</RightSpace>
//       <Footer>
//         <FooterContent />
//       </Footer>
//     </PageLayoutElement>
//   )
// }

export const BlogLayout = ({ children }) => {
  return (
    <BlogLayoutElement>
      <Header />
      <Nav>
        {/* <NavBar /> */}
        {/* <NewNavBar /> */}
        <Navbar navigation={navigation} />
      </Nav>
      <Main>
        {/* <div id="_home">&nbsp;</div> */}
        {children}
      </Main>
      <Footer>
        <FooterContent />
      </Footer>
    </BlogLayoutElement>
  )
}

export const LeftSpace = ({ children }) => {
  return <LeftSpaceElement>{children}</LeftSpaceElement>
}

export const RightSpace = ({ children }) => {
  return <RightSpaceElement>{children}</RightSpaceElement>
}

export const Header = ({ children }) => {
  return <HeaderElement>{children}</HeaderElement>
}

export const Nav = ({ children }) => {
  return <NavElement>{children}</NavElement>
}

export const Main = ({ children }) => {
  return <MainElement>{children}</MainElement>
}

export const Sidebar = ({ children }) => {
  return <SidebarElement>{children}</SidebarElement>
}

export const Footer = ({ children }) => {
  return <FooterElement>{children}</FooterElement>
}

export const THeader = ({ children }) => {
  return <THeaderElement>{children}</THeaderElement>
}

export const TNav = ({ children }) => {
  return <TNavElement>{children}</TNavElement>
}

export const TMain = ({ children }) => {
  return <TMainElement>{children}</TMainElement>
}

export const TFooter = ({ children }) => {
  return <TFooterElement>{children}</TFooterElement>
}

// export const TwoOptionsElements = styled.div`
//   display: flex;
//   flex-direction: column;
//   width: 100%;
//   justify-content: center;
//   align-items: center;
//   text-align: center;
//   padding-bottom: 20px;
//   column-gap: 1rem;

//   @media screen and (min-width: 720px) {
//     flex-direction: row;
//   }
// `

// export const TwoOptions = ({ children }) => {
//   return <TwoOptionsElements>{children}</TwoOptionsElements>
// }

export const TwoOptions = ({ children }) => {
  return (
    <div className="flex flex-col gap-y-4 md:flex-row w-full pb-5 gap-x-4 items-center">
      {children}
    </div>
  )
}
