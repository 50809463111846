import styled from "styled-components"

export const SocialIconElement = styled.div`
  ${"" /* const localPL = ${(props) => props.paddingLeft}; */}
  ${"" /* padding-left: (${(props) => (props.paddingLeft ? "10px" : "40px")}); */}
  ${"" /* padding-left:  */}

padding-left: ${(props) => props.paddingLeft};

  a {
    text-decoration: none;
    color: ${(props) => props.theme.colors.white};
    cursor: pointer;
    &:hover {
      color: ${(props) => props.hoverColor || props.theme.colors.primaryBlueNormal};
    }
  }
`

// display: flex;
//   align-items: center;
//   text-decoration: none;
//   padding: 0 1rem;
//   height: 100%;
//   cursor: pointer;
//   font-family: ${(props) => props.theme.fonts.menu};
//   font-size: 1rem;
//   font-weight: ${(props) => props.theme.fonts.weight600};

//   &.hover {
//     ${"" /* color: #0f93f2; */}
//     color: ${(props) => props.theme.colors.primaryBlueDarker};
//   }
//   &.active {
//     ${"" /* color: #0f93f2; */}
//     color: ${(props) => props.theme.colors.primaryBlueDarker};
//   }
